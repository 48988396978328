<template>
	<div id="mian">
		<div>
			<div class="con_from">
				<div class="lineP">
					<div class="item">
						<i>账户名称</i>
						<el-input v-model="query.keyword" maxlength="128" suffix-icon="el-icon-search"
						placeholder="账户名称/账户编号"></el-input>
					</div>
					<div class="item">
						<i>账户状态</i>
						<el-select v-model="query.enabledStatus" placeholder="全部">
							<el-option label="全部" value=""></el-option>
							<el-option label="激活" :value="true"></el-option>
							<el-option label="关闭" :value="false"></el-option>
						</el-select>
					</div>
					<div class="itembtn">
						<el-button class="searchBt" @click="getLists">查询</el-button>
					</div>
				</div>
			</div>
			<div class="tab1">
				<table class="tab">
					<tr class="tab_title">
						<td>
							<p>账户名称</p>
						</td>
						<td>
							<p>账户编号</p>
						</td>
						<td>
							<p>可取余额(元)</p>
						</td>
						<td>
							<p>可用垫资余额(元)/</p>
							<p>收款余额(元)</p>
						</td>
						<td>
							<p>账户状态</p>
						</td>
						<td>
							<p>操作</p>
						</td>
					</tr>
					<tr v-for="(v,i) in tabData" :key="i">
						<td>
							<p>{{v.mchName}}</p>
						</td>
						<td>
							<p>{{v.mchId}}</p>
						</td>
						<td>
							<p>{{v.balance/100 | formatMoney}}</p>
						</td>
						<td>
							<p>{{v.usableAdvanceAmount/100 | formatMoney}}</p>
						</td>
						<td>
							<p v-show="OFFON_EnabledStatus != 'TRADE:PAY:ACCOUNTS/ZHSTATUS'"> {{ v.enabledStatus==true ? "已开启" : "已关闭" }}</p>
							<p v-auth="'TRADE:PAY:ACCOUNTS/ZHSTATUS'">
								<el-switch v-model="v.enabledStatus" @change="setStatus(v)" active-color="#13ce66">
								</el-switch>
							</p>
						</td>
						<td>
							<p>
								<i class="lianjie" @click="getDetail(v)">查询</i>
								<i class="lianjie" style="padding-left:7px" @click="showLists(v)">明细</i>
								<!-- <i class="lianjie" style="padding-left:7px" @click="$router.push('/another_tab')">代付</i> -->
							</p>
						</td>
					</tr>
				</table>
			</div>
			<el-pagination ref="pagination" background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.pageNo"
			 :page-sizes="[10, 20, 50, 100]" :page-size="query.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalNum">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	import {
		getAccounts,
		getAccountsDetail,
		setAccountsStatus
	} from '@/api/trading/payment.js'
	import { mapState, mapMutations } from "vuex";
	export default {
		data() {
			return {
				query: {
					pageNo: 1,
					pageSize: 10,
					enabledStatus: '',
					keyword: ''
				},
				totalNum: 0,
				tabData: [],
				mchDrop: [],
				OFFON_EnabledStatus: 'TRADE:PAY:ACCOUNTS/ZHSTATUS',
			}
		},
		computed: {
			...mapState({
				tradingAccountList: "trading_account_list",
			}),
		},
		created() {
			if (this.tradingAccountList) {
				this.query = this.tradingAccountList.query;
			}
			this.$nextTick(() => {
				this.$refs.pagination.internalCurrentPage = this.query.pageNo;
			});
			this.getLists(false)
    	this.$enter(this.$route.path,this.getLists);
			this.listJurisdiction();
		},
		methods: {
			...mapMutations({
				setAccountList: "trading_setAccount_list",
			}),
			// 获取列表详情
			getLists(reset = true) {
        if(reset){this.query.pageNo = 1,this.query.keyword=this.query.keyword.trim()}
				getAccounts(this.query).then(res => {
					if (res) {
						this.tabData = []
						this.totalNum = res.resultData.totalNum
						res.resultData.accounts.forEach(v => {
							let obj = {
								mchId: v.mchId,
								mchName: v.mchName,
								balance: null,
								usableAdvanceAmount: null,
								enabledStatus: v.enabledStatus,
							}
							this.tabData.push(obj)
						})
            if(this.tabData.length == 0){
              this.$message({
                message:'暂无数据',
                duration: 1500
              })
            }
					}
				})
			},
			// 查询
			getDetail(value) {
				getAccountsDetail(value.mchId).then(res => {
					if (res.resultStatus) {
						this.$message.success('查询成功')
						let data = res.resultData
						value.balance = data.balance
						value.usableAdvanceAmount = data.usableAdvanceAmount
					}
				})
			},
			// 明细
			showLists(value) {
				this.$router.push({
					name: 'paidquery_dfcx',
					query: {
						name: value.mchName
					}
				})
			},
			// 页面大小改变
			handleSizeChange(size) {
				this.query.pageSize = size
				this.getLists()
			},
			handleCurrentChange(page) {
				this.query.pageNo = page
				this.getLists(false)
			},
			//启用、关闭状态
			setStatus(value){
				this.$confirm('此操作将修改状态, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let data = {
						mchId: value.mchId,
						enabledStatus: value.enabledStatus
					}
					setAccountsStatus(data).then(res=>{
						if(!res){
							value.enabledStatus = value.enabledStatus?false:true
						}
					})
        }).catch(() => {
					value.enabledStatus = value.enabledStatus?false:true
          this.$message({
            type: 'info',
            message: '已取消操作'
          });
        });
			},
			//判断列表按钮是否有权限
			listJurisdiction(){
					const _resources = JSON.parse(sessionStorage.getItem('userInfo')).resources;
					if(_resources=='ALL'){
							this.OFFON_EnabledStatus = 'TRADE:PAY:ACCOUNTS/ZHSTATUS';
					}else{
						if(_resources.split(';').indexOf('TRADE:PAY:ACCOUNTS/ZHSTATUS') !== -1 ){
							this.OFFON_EnabledStatus = 'TRADE:PAY:ACCOUNTS/ZHSTATUS';
						}else{
							this.OFFON_EnabledStatus = "";
						}
					}
			},
		},
		beforeDestroy() {
			let data = {
				query: this.query,
			};
			this.setAccountList(data);
		},
	};
</script>

<style scoped>

</style>
